@import "~antd/dist/antd.variable.less";

// .ant-list-item-meta-content > h4,
// .ant-list-item-meta-title {
//   margin: 0;
// }

.ant-list-item-meta-title {
  margin-bottom: -4px;
}

.ant-modal {
  top: 20px;
}

.ant-card,
.ant-modal-content {
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 3px;
  border-radius: 8px;
}

.ant-layout {
  background-color: #f3f2f2;
}

.ant-btn {
  border-radius: 25px;
}

.fitImageToContent {
  img {
    max-width: 100%;
  }

  video {
    max-width: 100%;
  }
}

.ant-table-body {
  overflow: auto !important;
}

.ant-drawer-title,
.ant-modal-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-drawer-body {
  overflow-x: hidden;
}

.ant-drawer-title {
  display: block;
  width: 450px;
}

.removeActionMargin {
  .ant-list-item-action {
    margin-left: 0;
  }
}

.wrapTextEllipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-form {
  .ant-form-item-label {
    padding: 0px;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-form-item-explain,
  .ant-form-item-label > label {
    font-size: 14px;
  }
}

@primary-color: #00243D;@link-color: #00243D;@border-radius-base: 4px;@font-size-base: 16px;@font-family: Source Sans Pro, sans-serif;